import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opensource',
  templateUrl: './opensource.component.html',
  styleUrls: ['./opensource.component.css']
})
export class OpensourceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
