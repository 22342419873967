import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-educational-institution',
  templateUrl: './educational-institution.component.html',
  styleUrls: ['./educational-institution.component.css']
})
export class EducationalInstitutionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
