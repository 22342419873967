import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-products-home',
  templateUrl: './our-products-home.component.html',
  styleUrls: ['./our-products-home.component.css']
})
export class OurProductsHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
