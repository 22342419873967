import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent {

  constructor (private httpService: HttpClient ) { }
  arrProductsHome: string [];
  arrClients: string [];

  ngOnInit () {
    this.httpService.get('./assets/json/products_home.json').subscribe(
      data => {
        this.arrProductsHome = data as string [];	 // FILL THE ARRAY WITH DATA.
      }
    );

    this.httpService.get('./assets/json/clients.json').subscribe(
      data => {
        this.arrClients = data as string [];	 // FILL THE ARRAY WITH DATA.
      }
    );


  }

}
