import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-webschool',
  templateUrl: './webschool.component.html',
  styleUrls: ['./webschool.component.css']
})
export class WebschoolComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
   
  }

}
