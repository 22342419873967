import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ems',
  templateUrl: './ems.component.html',
  styleUrls: ['./ems.component.css']
})
export class EmsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

