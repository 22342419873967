import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule, JsonpModule } from "@angular/http";
import { FormsModule,ReactiveFormsModule  }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


//services 
import {OurservicesService} from './services/ourservices.service';

//Components all
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainpageComponent } from './mainpage/mainpage.component';
import { CompanyComponent } from './company/company.component';
import { ContactComponent } from './contact/contact.component';
import { DemoRequestComponent } from './demo-request/demo-request.component';
import { ClientsComponent } from './clients/clients.component';
import { CareersComponent } from './careers/careers.component';
import { ProductBrochureComponent } from './product-brochure/product-brochure.component';
import { CompletionComponent } from './completion/completion.component';
import { PhotosVideosComponent } from './photos-videos/photos-videos.component';
import { VisionComponent } from './vision/vision.component';
import { GemsComponent } from './gems/gems.component';
import { ProductSidebarComponent } from './product-sidebar/product-sidebar.component';
import { WebschoolComponent } from './webschool/webschool.component';
import { ItutorComponent } from './itutor/itutor.component';
import { HrmsComponent } from './hrms/hrms.component';
import { EdocComponent } from './edoc/edoc.component';
import { EAssetComponent } from './e-asset/e-asset.component';
import { DLibComponent } from './d-lib/d-lib.component';
import { HmsComponent } from './hms/hms.component';
import { TmsComponent } from './tms/tms.component';
import { EmsComponent } from './ems/ems.component';
import { HomebannerComponent } from './homebanner/homebanner.component';
import { ServicepageSidebarComponent } from './servicepage-sidebar/servicepage-sidebar.component';
import { SoftwareComponent } from './software/software.component';
import { ELearningComponent } from './e-learning/e-learning.component';
import { EducationalInstitutionComponent } from './educational-institution/educational-institution.component';
import { EGovernanceComponent } from './e-governance/e-governance.component';
import { NetworkComponent } from './network/network.component';
import { OpensourceComponent } from './opensource/opensource.component';
import { WebdesignComponent } from './webdesign/webdesign.component';
import { ResearchAndDevelopmentComponent } from './research-and-development/research-and-development.component';
import { WeInspireComponent } from './we-inspire/we-inspire.component';
import { SmartWorkComponent } from './smart-work/smart-work.component';
import { ProduceEdceptionalComponent } from './produce-edceptional/produce-edceptional.component';
import { MyDirDirective } from './shared/my-dir.directive';
import { MyPipePipe } from './shared/my-pipe.pipe';
import { OurProductsHomeComponent } from './our-products-home/our-products-home.component';
import { MissionComponent } from './mission/mission.component';
import { EventsComponent } from './events/events.component';


const appRoutes: Routes = [
  { path: 'home',  component: MainpageComponent  },
  { path: 'contact',  component: ContactComponent },
  { path: 'introduction',  component: CompanyComponent},
  { path: 'clients',  component: ClientsComponent},
  { path: 'careers',  component: CareersComponent},
  { path: 'customers-feedback',  component: CompletionComponent},
  { path: 'product-brochures',  component: ProductBrochureComponent},
  { path: 'photos-and-videos', component:PhotosVideosComponent },
  { path: 'vision',  component: VisionComponent},
  { path: 'mission',  component: MissionComponent},
  { path: 'demo-request-form',  component: DemoRequestComponent},
  { path: 'gems',  component: GemsComponent},
  { path: 'webschool',  component: WebschoolComponent},
  { path: 'i-tutor',  component: ItutorComponent},
  { path: 'hrms',  component: HrmsComponent},
  { path: 'e-docs',  component: EdocComponent},
  { path: 'e-asset',  component: EAssetComponent},
  { path:'digital-library', component:DLibComponent},
  { path:'transportation-management-system', component:TmsComponent},
  { path: 'hostel-management-system',  component: HmsComponent},
  { path: 'e-messaging-software',  component: EmsComponent},
  { path: 'services/software-development-and-consulting',  component: SoftwareComponent},
  { path: 'services/e-learning-solution',  component: ELearningComponent},
  { path: 'services/educational-institution-products',  component: EducationalInstitutionComponent},
  { path: 'services/e-governance',  component: EGovernanceComponent},
  { path: 'services/networking-and-infrastructure-solutions',  component: NetworkComponent},
  { path: 'services/opensource-product-customization',  component: OpensourceComponent},
  { path: 'services/web-design-and-development',  component: WebdesignComponent},
  { path: 'research-and-development',  component: ResearchAndDevelopmentComponent},
  { path: 'we-inspire',  component: WeInspireComponent},
  { path: 'smart-work',  component: SmartWorkComponent},
  { path: 'we-produce-exceptional-result',  component: ProduceEdceptionalComponent},
  { path: 'events',  component: EventsComponent},
  { path: '', redirectTo: '/',pathMatch: 'full'},
  { path: '**',component: MainpageComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainpageComponent,
    CompanyComponent,
    ContactComponent,
    DemoRequestComponent,
    ClientsComponent,
    CareersComponent,
    ProductBrochureComponent,
    CompletionComponent,
    PhotosVideosComponent,
    VisionComponent,
    GemsComponent,
    ProductSidebarComponent,
    WebschoolComponent,
    ItutorComponent,
    HrmsComponent,
    EdocComponent,
    EAssetComponent,
    DLibComponent,
    HmsComponent,
    TmsComponent,
    EmsComponent,
    HomebannerComponent,
    ServicepageSidebarComponent,
    SoftwareComponent,
    ELearningComponent,
    EducationalInstitutionComponent,
    EGovernanceComponent,
    NetworkComponent,
    OpensourceComponent,
    WebdesignComponent,
    ResearchAndDevelopmentComponent,
    WeInspireComponent,
    SmartWorkComponent,
    ProduceEdceptionalComponent,
    MyDirDirective,
    MyPipePipe,
    OurProductsHomeComponent,
    MissionComponent,
    EventsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JsonpModule,
    RouterModule.forRoot(appRoutes,{initialNavigation: true}),
    HttpModule,FormsModule,ReactiveFormsModule
  ],
  providers: [OurservicesService],
  bootstrap: [AppComponent]
})
export class AppModule { }
