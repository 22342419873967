import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gems',
  templateUrl: './gems.component.html',
  styleUrls: ['./gems.component.css']
})
export class GemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
